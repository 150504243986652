<template>
  <div class="hosting-transfer-nameserver-info">
    <div class="header-no-padding"> {{ headerText }}</div>
    <div v-if="nameserversVerified" class="pt-2">
      <div>We have completed your web hosting transfer.</div>
      <div>Press 'Continue' to proceed.</div>
    </div>
    <div v-else-if="pendingHostingInfo.requestCall" class="pt-2">
      <div>We have received your request for a call to help set up your hosting transfer.</div>
      <div>One of our hosting experts will be in touch with you shortly.</div>
    </div>
    <div v-else class="pt-2">
      <div class="pb-2">
        Next, we will start the web hosting transfer process.
      </div>
      <ul>
        <li>You will receive a notification once the transfer process is complete</li>
        <li>If any problems arise, we'll reach out to you regarding the next steps</li>
      </ul>
      <div class="pt-2">Let's head over to the Websites page to manage your service.</div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'HostingTransferStatus',
  props: {
    pendingHostingInfo: {
      type: Object,
      required: true,
    },
    bus: {
      type: Object,
      required: true,
    },
  },
  computed: {
    nameserversVerified() {
      return this.pendingHostingInfo.nameservers?.status === "internal"
    },
    headerText() {
      return this.nameserversVerified ? "Hosting transfer process complete" : "Hosting transfer request submitted"
    },
  },
}
</script>


<style scoped lang='scss'>
.hosting-transfer-nameserver-info{
  .header-no-padding{
    font-size: 2.125em;
    font-weight: $ct-ui-font-weight-7;
  }
}
</style>
