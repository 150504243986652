var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "hosting-transfer-nameserver-info" }, [
    _c("div", { staticClass: "header-no-padding" }, [
      _vm._v(" " + _vm._s(_vm.headerText)),
    ]),
    _vm.nameserversVerified
      ? _c("div", { staticClass: "pt-2" }, [
          _c("div", [_vm._v("We have completed your web hosting transfer.")]),
          _c("div", [_vm._v("Press 'Continue' to proceed.")]),
        ])
      : _vm.pendingHostingInfo.requestCall
      ? _c("div", { staticClass: "pt-2" }, [
          _c("div", [
            _vm._v(
              "We have received your request for a call to help set up your hosting transfer."
            ),
          ]),
          _c("div", [
            _vm._v(
              "One of our hosting experts will be in touch with you shortly."
            ),
          ]),
        ])
      : _c("div", { staticClass: "pt-2" }, [
          _c("div", { staticClass: "pb-2" }, [
            _vm._v(
              "\n      Next, we will start the web hosting transfer process.\n    "
            ),
          ]),
          _vm._m(0),
          _c("div", { staticClass: "pt-2" }, [
            _vm._v(
              "Let's head over to the Websites page to manage your service."
            ),
          ]),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _vm._v(
          "You will receive a notification once the transfer process is complete"
        ),
      ]),
      _c("li", [
        _vm._v(
          "If any problems arise, we'll reach out to you regarding the next steps"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }